import React, { useState, useContext, Fragment, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "../Layout"
import Section from "../Elements/Section"
import Message from "../Elements/Message"
import Container from "../Layout/Container"
import ActionButtons from "../Elements/ActionButtons"
import DeliveryAddress from "./Checkout/DeliveryAddress"
import { generateFormField } from "../Elements/Form/services/form"

import { useSession } from "services/hooks/useSession"
import { getSignedInUser } from "../Auth/services/user"
import { generateInitialValues } from "./services/checkout"
import { formSchema } from "../MedEnsure/Request/utils/formSchema"
import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"

let { parseFormField } = require("../../services/airtable")

const DoctorEnrollmentForm = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const {
    formValues,
    isFormValuesEmpty,
    initializeFormValues,
    handleFormChange,
    clearFormValues,
  } = useSession("orderCheckout")

  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )
  let userAddresses = getSignedInUser()?.addresses
  let addressList = userAddresses?.addresses || []
  addressList = addressList?.filter(
    (address) => address?.province === "Metro Manila"
  )

  const addressFieldNames = {
    addressType: `deliveryAddress.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
    barangay: `deliveryAddress.barangay`,
  }

  useEffect(() => {
    initializeFormValues()
    // eslint-disable-next-line
  }, [])

  const handleSubmit = async (values) => {
    setLoading(true)
    // Comment this out if you want to continue testing out form submissions
    clearFormValues()

    await epharmacyDispatch({
      type: "SAVE_EPHARMACY",
      payload: values,
    })

    navigate("/epharmacy/payment")
    setLoading(false)
  }

  return (
    <Layout
      {...module}
      pageContext={pageContext}
      isPrivate={pageContext?.module?.isPrivate}
    >
      <Container isCentered>
        <Formik
          initialValues={
            isFormValuesEmpty
              ? generateInitialValues({ epharmacyState })
              : { ...formValues }
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, setFieldError, isValid, submitCount }) => (
            <Form onChange={(event) => handleFormChange(values, event)}>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId}
                  className="mt-1 mb-3"
                  isSectionRequired={section.isSectionRequired}
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      {section?.message}
                    </Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (field?.name === "deliveryAddress") {
                      return (
                        <DeliveryAddress
                          setFieldValue={setFieldValue}
                          values={values}
                          addressFieldNames={addressFieldNames}
                          addresses={addressList}
                        />
                      )
                    } else if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values,
                            setFieldValue,
                            setFieldError,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }

                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{ label: "Back", link: pageContext.backPath }}
                submit={{
                  label: "Next",
                  loading: loading,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollmentForm
