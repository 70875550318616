import { getSignedInUser } from "../../Auth/services/user"

export const generateInitialValues = ({ epharmacyState }) => {
  let userData = getSignedInUser()?.userData
  return {
    ...epharmacyState,
    ...userData,
    firstName: epharmacyState?.firstName || userData?.firstName,
    middleName: epharmacyState?.middleName || userData?.middleName,
    lastName: epharmacyState?.lastName || userData?.lastName,
    mobileNumber:
      epharmacyState?.mobileNumber ||
      userData?.mobileNumber?.replace("+63", ""),
    email: epharmacyState?.email || userData?.email,
    authorizedPerson:
      epharmacyState?.authorizedPerson || !!userData?.firstName
        ? `${userData?.firstName || epharmacyState?.authorizedPerson}${
            !!userData?.lastName ? ` ${userData?.lastName}` : ""
          }`
        : "",
    authorizedPersonMobileNumber:
      epharmacyState?.authorizedPersonMobileNumber || !!userData?.mobileNumber
        ? userData?.mobileNumber?.replace("+63", "") ||
          epharmacyState?.authorizedPersonMobileNumber
        : "",
  }
}
