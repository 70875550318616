import React, { useEffect, Fragment } from "react"

import Address from "../../Elements/Address"
import FormCheckbox from "../../Elements/Form/FormCheckbox"
import FormStyledRadio from "../../Elements/Form/FormStyledRadio"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkedAlt,
  faHome,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons"

const DeliveryAddress = ({
  values,
  addressFieldNames,
  setFieldValue,
  addresses,
}) => {
  const handleDeliverySelect = (address) => {
    setFieldValue("deliveryAddress.addressType", address.type)
    setFieldValue("deliveryAddress.streetAddress", address.streetAddress)
    setFieldValue("deliveryAddress.city", {
      value: address.city,
      label: address.city,
    })
    setFieldValue("deliveryAddress.province", {
      value: address.province,
      label: address.province,
    })
    setFieldValue("deliveryAddress.barangay", {
      value: address.barangay,
      label: address.barangay,
    })
    setFieldValue("preferredDeliveryDate", {
      month: {
        value: "",
      },
      date: {
        value: "",
      },
      year: {
        value: "",
      },
    })
  }

  useEffect(() => {
    if (addresses?.[0]) handleDeliverySelect(addresses?.[0])
  }, [])

  let { addressType, streetAddress, city, province } = values.deliveryAddress
  let valueKey = `${addressType}${streetAddress}${city?.value}${province?.value}`

  return (
    <Fragment>
      {addresses && addresses?.length > 0 ? (
        <FormCheckbox
          name="overrideAddress"
          values={values.overrideAddress}
          options={["Use different address"]}
          isRequired
        />
      ) : null}
      {values?.overrideAddress.length === 0 &&
        addresses?.map((address) => {
          let { type, streetAddress, city, province } = address
          let addressKey = `${type}${streetAddress}${city}${province}`

          return (
            <FormStyledRadio
              isActive={valueKey === addressKey}
              onClick={() => {
                handleDeliverySelect(address)
              }}
            >
              <div className="is-size-7">
                <p className="is-size-6 ml-0">
                  <span className="icon mr-1">
                    <FontAwesomeIcon
                      icon={address.type === "home" ? faHome : faBuilding}
                    />
                  </span>
                  Address Type:{" "}
                  <b>
                    {address.type}{" "}
                    {address.primary && (
                      <span className="tag is-rounded">Default</span>
                    )}
                  </b>
                </p>
                <p className="is-size-6">
                  <span className="icon mr-1">
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                  </span>
                  {address.street || address.streetAddress},{" "}
                  {address.barangay ? `${address.barangay}, ` : ""}
                  {address.city}, {address.province}{" "}
                  {address.notes ? `(${address.notes})` : ""}
                </p>
              </div>
            </FormStyledRadio>
          )
        })}
      {(!addresses ||
        addresses?.length === 0 ||
        values?.overrideAddress.length > 0) && (
        <Fragment>
          <Address
            fieldNames={addressFieldNames}
            values={values}
            isRequired
            setFieldValue={setFieldValue}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default DeliveryAddress
