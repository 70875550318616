import { useState } from "react"

import { isBrowser } from "../general"

export const useSession = (formName) => { 
  const [ formValues, setFormValues ] = useState({})
  const isFormValuesEmpty = Object.keys(formValues).length === 0 ? true : false

  const initializeFormValues = () => { 
    if (isBrowser()) {
      const storedValues = sessionStorage.getItem(`${formName}FormValues`)
      if (storedValues) { 
        setFormValues(JSON.parse(storedValues))
      }
    }
  }
  
  const handleFormChange = (values, event) => {
    const { name, value } = event.target
    if (isBrowser()) {
      sessionStorage.setItem(`${formName}FormValues`, JSON.stringify({
        ...values,
        [name]: value
      }));
    }
  }

  const clearFormValues = () => {
    if (isBrowser()) {
      sessionStorage.removeItem(`${formName}FormValues`);
    }
  }

  return { formValues, isFormValuesEmpty, initializeFormValues, handleFormChange, clearFormValues }
}